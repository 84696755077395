import {Component} from '@angular/core';

@Component({
  selector: 'app-dsm-updates-management',
  templateUrl: './dsm-updates-management.component.html',
  styleUrls: ['./dsm-updates-management.component.css']
})
export class DsmUpdatesManagementComponent {

}

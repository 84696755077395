import {Component} from '@angular/core';

@Component({
  selector: 'app-dsm-updates',
  templateUrl: './dsm-updates.component.html',
  styleUrls: ['./dsm-updates.component.css']
})
export class DsmUpdatesComponent {

}

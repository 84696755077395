import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-community-calendar",
  templateUrl: "./community-calendar.component.html",
  styleUrls: ["./community-calendar.component.scss"]
})
export class CommunityCalendarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
